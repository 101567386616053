<template>
  <form
    ref="form"
    :action="action"
    :method="formType"
    accept-charset="UTF-8"
    class="grid grid-cols-12 w-full"
  >
    <div class="col-start-3 col-span-8 mb-12">
      <h1 class="beam-h1 text-left mb-12 mt-6">Your Needs</h1>
      <!-- This field needs to be fully removed in the case of a member
           using the form -->
      <member-form-field
        key="member_id"
        :field="form.member_id"
        v-model="form.member_id.value"
        class="mb-6"
      >
        forcerefresh
      </member-form-field>

      <member-form-section
        section-key="your_household"
        :fields="fieldsForSection('your_household')"
        :form-section-schema="formSectionSchema"
      >
      </member-form-section>

      <warning-panel v-if="showNumberOfAdultsWarning"></warning-panel>

      <!--
        Dynamic array fields like this are currently not
        supported by MemberFormSection and are bespoke for
        this form alone.
      -->
      <template v-if="!showNumberOfAdultsWarning">
        <template :key="index" v-for="(field, index) in childFields">
          <h2 class="beam-h2 text-left mb-4">
            Your {{ numberToOrdinalWord(index) }} child
          </h2>
          <fieldset
            class="mb-12 bg-white border border-pale-grey-300 p-10 rounded-lg space-y-12"
          >
            <member-form-field
              v-for="fieldKey in ['name', 'date_of_birth', 'sex']"
              :key="`child_${index}_${fieldKey}`"
              :field="form.children.value[index][fieldKey]"
              v-model="form.children.value[index][fieldKey].value"
            >
              forcerefresh
            </member-form-field>
          </fieldset>
        </template>

        <template :key="index" v-for="(field, index) in adultFields">
          <h2 class="beam-h2 text-left mb-4">
            {{ capitalizedNumberToOrdinalWord(index) }} adult
          </h2>
          <fieldset
            class="mb-12 bg-white border border-pale-grey-300 p-10 rounded-lg space-y-12"
          >
            <member-form-field
              v-for="fieldKey in [`name`, `date_of_birth`, `relationship`]"
              :key="`adult_${index}_${fieldKey}`"
              :field="form.adults.value[index][fieldKey]"
              v-model="form.adults.value[index][fieldKey].value"
            >
              forcerefresh
            </member-form-field>
          </fieldset>
        </template>
      </template>

      <warning-panel v-if="showAdultRelationshipWarning"></warning-panel>

      <div
        v-if="showEntitlement"
        class="bg-navy-800 rounded-lg p-10 mb-6 text-center text-white"
      >
        <p class="text-2xl mb-4">You are entitled to a</p>
        <p class="font-medium text-3xl">
          {{ bedroomEntitlementText }}
        </p>
      </div>

      <member-form-section
        section-key="housing_needs"
        :fields="fieldsForSection('housing_needs')"
        :form-section-schema="formSectionSchema"
      >
      </member-form-section>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary button-large w-full"
            :disabled="saveDisabled"
            :class="{
              'opacity-50 cursor-not-allowed': saveDisabled,
            }"
          >
            Save and continue
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import MemberFormShared from "../member-forms/MemberFormShared"
const ordinal = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth",
  6: "sixth",
  7: "seventh",
  8: "eighth",
  9: "ninth",
  10: "tenth",
  11: "eleventh",
  12: "twelfth",
  13: "thirteenth",
  14: "fourteenth",
  15: "fifteenth",
}
export default {
  mixins: [MemberFormShared],
  props: {
    existingChildren: {
      type: Array,
      default: [],
    },
    existingAdults: {
      type: Array,
      default: [],
    },
    action: {
      type: String,
    },
  },
  methods: {
    showField(id) {
      if (
        !["number_of_adults", "number_of_children"].includes(id) &&
        this.showNumberOfAdultsWarning
      ) {
        return false
      }

      if (
        (!["number_of_adults", "number_of_children"] ||
          !id?.startsWith("adult_")) &&
        this.showAdultRelationshipWarning
      ) {
        return false
      }

      switch (id) {
        case "factors_for_non_shared_housing":
          return (
            this.thisForm.number_of_adults.value === 0 &&
            this.thisForm.number_of_children.value === 0
          )
        default:
          return true
      }
    },
    numberToOrdinalWord(index) {
      return ordinal[index + 1]
    },
    capitalizedNumberToOrdinalWord(index) {
      const header = this.numberToOrdinalWord(index)
      return header.charAt(0).toUpperCase() + header.slice(1)
    },
    fetchEntitlement() {
      const form = this.thisForm

      if (this.showEntitlement) {
        const params = {
          member_id: form.member_id.value,
          adults: form.adults.value.map((adult) => ({
            date_of_birth: adult.date_of_birth.value,
            relationship: adult.relationship.value,
          })),
          children: form.children.value.map((child) => ({
            date_of_birth: child.date_of_birth.value,
            sex: child.sex.value,
          })),
          factors_for_non_shared_housing:
            form.factors_for_non_shared_housing.value,
        }
        this.axios
          .get(`/budget_calculator/bedroom_eligibility`, {
            params,
            paramsSerializer: (params) => {
              // Prevent axios from putting indexes in the brackets for the query string arrays
              // See: https://github.com/axios/axios/issues/5094
              return qs.stringify(params, { arrayFormat: "brackets" })
            },
          })
          .then(({ data }) => {
            this.bedroomSizeEntitlement = data.bedroom_size
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    setPropertySizeChosenOptions() {
      const entitlement = this.bedroomSizeEntitlement

      if (!entitlement) {
        this.form.property_size_chosen.radio_options =
          this.form.property_size_chosen.dynamic_radio_options
        return
      }

      const entitlementIndex =
        this.form.property_size_chosen.dynamic_radio_options.findIndex(
          (option) => option == entitlement
        )

      this.form.property_size_chosen.radio_options =
        this.form.property_size_chosen.dynamic_radio_options.slice(
          0,
          entitlementIndex + 1
        )
    },
  },
  data() {
    return {
      bedroomSizeEntitlement: null,
    }
  },
  computed: {
    bedroomEntitlementText() {
      if (!this.bedroomSizeEntitlement) {
        return "Calculating..."
      }

      const startsWithNumber = this.bedroomSizeEntitlement.match(/^\d+ bed/)

      if (startsWithNumber) {
        return `${this.bedroomSizeEntitlement}room property`
      } else {
        return this.bedroomSizeEntitlement
      }
    },
    showEntitlement() {
      if (this.showNumberOfAdultsWarning || this.showAdultRelationshipWarning) {
        return false
      }

      let form = this.thisForm
      const hasCohabitants =
        typeof form.number_of_adults.value != "undefined" &&
        typeof form.number_of_children.value != "undefined"

      const allAdultsFilledIn = form.adults.value.every(
        (adult) =>
          !!adult.name.value.trim() &&
          !!adult.date_of_birth.value.trim() &&
          !!adult.relationship.value
      )

      const allChildrenFilledIn = form.children.value.every(
        (child) =>
          !!child.name.value.trim() &&
          !!child.date_of_birth.value.trim() &&
          !!child.sex.value
      )

      return hasCohabitants && allAdultsFilledIn && allChildrenFilledIn
    },
    showNumberOfAdultsWarning() {
      const form = this.thisForm
      return form.number_of_adults.value > 1
    },
    showAdultRelationshipWarning() {
      const form = this.thisForm
      // Return true if any of the adults have a relationship value which is
      // "Friend", "Family member" or "Other"
      return form.adults.value.some(
        (adult) =>
          adult.relationship.value === "Friend" ||
          adult.relationship.value === "Family member" ||
          adult.relationship.value === "Other"
      )
    },
    saveDisabled() {
      return this.showAdultRelationshipWarning || this.showNumberOfAdultsWarning
    },
    childFields() {
      let form = this.thisForm

      if (form.children.value.length < form.number_of_children.value) {
        for (
          let i = form.children.value.length;
          i < form.number_of_children.value;
          i++
        ) {
          form.children.value.push({
            id: {
              id: `child_${i}_id`,
              value: this.existingChildren[i]?.id || "",
              error_text: "",
              required: false,
              hidden: true,
            },
            name: {
              id: `child_${i}_name`,
              question: `${this.capitalizedNumberToOrdinalWord(
                i
              )} child's name`,
              value: this.existingChildren[i]?.first_name || "",
              error_text: "",
              required: true,
            },
            date_of_birth: {
              id: `child_${i}_date_of_birth`,
              question: `Date of birth`,
              value: this.existingChildren[i]?.date_of_birth || "",
              error_text: "",
              required: true,
              date: true,
            },
            sex: {
              id: `child_${i}_sex`,
              question: `Sex`,
              value: this.existingChildren[i]?.sex || null,
              error_text: "",
              required: true,
              radio_options: ["Female", "Male"],
            },
          })
        }
      } else if (form.children.value.length > form.number_of_children.value) {
        form.children.value.splice(form.number_of_children.value)
      }

      return form.children.value
    },
    adultFields() {
      let form = this.thisForm

      if (form.adults.value.length < form.number_of_adults.value) {
        for (
          let i = form.adults.value.length;
          i < form.number_of_adults.value;
          i++
        ) {
          form.adults.value.push({
            id: {
              id: `adult_${i}_id`,
              value: this.existingAdults[i]?.id || "",
              error_text: "",
              required: false,
              hidden: true,
            },
            name: {
              id: `adult_${i}_name`,
              question: `${this.capitalizedNumberToOrdinalWord(
                i
              )} adult's name`,
              value: this.existingAdults[i]?.first_name || "",
              error_text: "",
              required: true,
            },
            date_of_birth: {
              id: `adult_${i}_date_of_birth`,
              question: `Date of birth`,
              value: this.existingAdults[i]?.date_of_birth || "",
              error_text: "",
              required: true,
              date: true,
            },
            relationship: {
              id: `adult_${i}_relationship`,
              question: `What is this person's relationship to you?`,
              value: this.existingAdults[i]?.is_members_partner
                ? "Partner"
                : null,
              error_text: "",
              required: true,
              radio_options: ["Partner", "Friend", "Family member", "Other"],
            },
          })
        }
      } else if (form.adults.value.length > form.number_of_adults.value) {
        form.adults.value.splice(form.number_of_adults.value)
      }

      return form.adults.value
    },
  },
  watch: {
    childFields: {
      handler() {
        this.fetchEntitlement()
      },
      deep: true,
    },
    adultFields: {
      handler() {
        this.fetchEntitlement()
      },
      deep: true,
    },
    "form.factors_for_non_shared_housing.value": {
      handler() {
        this.fetchEntitlement()
      },
      deep: true,
    },
    showEntitlement() {
      this.fetchEntitlement()
    },
    bedroomSizeEntitlement() {
      this.setPropertySizeChosenOptions()
    },
  },
  mounted() {
    this.setPropertySizeChosenOptions()
  },
}
</script>
