<template>
  <div>
    <div v-if="!surveyResponded">
      <input
        type="text"
        v-model.trim="surveyResponse"
        name="survey-response"
        class="beam-text-input w-full"
        placeholder="Where did you first hear about Beam?"
      />
      <button
        class="button button-secondary mt-2 px-8 py-2"
        @click="saveSurveyAnswer"
      >
        Submit
      </button>
    </div>

    <div v-if="surveyResponded">Thank you!</div>
  </div>
</template>

<script>
export default {
  props: {
    newUserId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      surveyResponse: "",
      surveyResponded: false,
    }
  },
  methods: {
    saveSurveyAnswer: function () {
      if (!this.surveyResponse) {
        return
      }

      const data = {
        survey: {
          user_id: this.newUserId,
          answers: { question_5: this.surveyResponse },
        },
      }
      this.surveyResponded = true
      this.axios.post("/survey.json", data)
    },
  },
}
</script>
