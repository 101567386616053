<template>
  <div>
    <header>
      <h1 class="beam-h1">
        Make an <span class="yellow-underline">impact</span> with your career
      </h1>

      <div class="mt-10 flex flex-col sm:flex-row">
        <div class="flex flex-col self-center sm:mr-16">
          <div
            class="mx-auto w-full text-center sm:max-w-3xl sm:text-left sm:text-lg"
          >
            We’re one of London’s 10 hottest startups (WIRED) and one of the
            UK’s top 20 startups (Evening Standard). And we’re growing fast.
          </div>

          <div
            class="mx-auto mt-10 w-full text-center sm:max-w-3xl sm:text-left sm:text-lg"
          >
            Looking to do the most purposeful work of your career? Looking for a
            team that’s high-performance and low-ego? You’re in the right place.
          </div>
        </div>
        <div class="mt-10 flex-shrink-0 sm:mt-0 sm:w-1/2">
          <div class="video-wrapper">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/oNogjZZ6FgA?modestbranding=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div class="mt-8 text-center sm:mt-16">
        <button
          v-scroll-to="{ el: '#positions', offset: -40 }"
          class="button button-secondary inline-block hidden px-16 py-3"
        >
          Browse our <span class="js-open-positions"></span> open roles
        </button>
      </div>
    </header>

    <main>
      <div
        class="full-screen-width mt-8 flex flex-wrap justify-center overflow-hidden sm:mt-16"
      >
        <gallery-thumb
          v-for="image in galleryImages"
          :key="image.path"
          :path="image.path"
          :alt="image.alt"
        >
        </gallery-thumb>
      </div>

      <!-- Testimonials -->
      <section class="mt-16">
        <div class="full-screen-mobile bg-pale-grey-100 px-4 py-10 sm:py-12">
          <div class="beam-h2">What Beamers say</div>

          <div class="mt-8 flex flex-col flex-wrap justify-center sm:flex-row">
            <testimonial
              v-for="testimonial in testimonials"
              :key="testimonial.id"
              :testimonial="testimonial"
              class="h-full"
            ></testimonial>
          </div>
        </div>
      </section>

      <!-- Core Values -->
      <div
        class="full-screen-mobile mt-10 items-baseline bg-pale-grey-100 p-8 sm:mt-20 sm:rounded sm:p-12 lg:flex lg:flex-col"
      >
        <div class="m-8 w-full">
          <h2 class="beam-h2 mb-10 lg:text-center">Our mission and values</h2>
          <div class="m-4 text-sm sm:text-base lg:text-center">
            Beam's mission is to give everyone access to human-centred welfare
            services.
          </div>
          <div class="text-sm sm:text-base lg:text-center">
            Read more about our values and culture in
            <a
              href="https://docs.google.com/document/d/1W2pEQg2sSnU1KBaJp5gX6cWowLjSoDmn/edit#heading=h.a63wup1uy8jg"
              class="blue-link"
              target="_blank"
              >Beam Life</a
            >.
          </div>
        </div>

        <div class="grid text-center sm:grid-cols-2 lg:text-left">
          <div class="mt-8 rounded bg-white p-5 sm:p-8 lg:mx-8 lg:p-10">
            <div class="font-medium sm:text-xl">⚡️ We make an impact</div>
            <div class="mt-5 text-sm sm:text-base">
              We change lives and fix social problems by setting and achieving
              ambitious targets
            </div>
          </div>

          <div class="mt-8 rounded bg-white p-5 sm:p-8 lg:mx-8 lg:p-10">
            <div class="font-medium sm:text-xl">
              🗯 We’re open and transparent
            </div>
            <div class="mt-5 text-sm sm:text-base">
              We communicate openly and transparently with each other and the
              world
            </div>
          </div>

          <div class="mt-8 rounded bg-white p-5 sm:p-8 lg:mx-8 lg:p-10">
            <div class="font-medium sm:text-xl">🌱 We get better together</div>
            <div class="mt-5 text-sm sm:text-base">
              We support each other to improve and actively work on personal
              growth - with no ego
            </div>
          </div>

          <div class="mt-8 rounded bg-white p-5 sm:p-8 lg:mx-8 lg:p-10">
            <div class="font-medium sm:text-xl">
              🔦 We’re beams of energy and focus
            </div>
            <div class="mt-5 text-sm sm:text-base">
              We bring energy and strive for laser focus in our work
            </div>
          </div>
        </div>

        <!-- Images -->
        <div
          class="mt-24 w-full justify-between text-center lg:flex lg:flex-row"
        >
          <!-- LinkedIn -->
          <div class="flex flex-col justify-center text-center lg:w-1/2">
            <img
              class="mx-28 max-h-96 self-center rounded"
              v-lazy="'/images/linkedin-top-uk-startups.png'"
              alt="We were listed as part of LinkedIn's Top UK Startups 2022!"
            />
            <div class="mt-6 text-center">
              Named as one of the UK’s top 15 startups by LinkedIn
            </div>
          </div>

          <!-- Escape the City -->
          <div
            class="mt-24 flex flex-col justify-center text-center lg:mt-0 lg:w-1/2"
          >
            <img
              class="mx-28 max-h-96 self-center rounded"
              v-lazy="'/images/escape_100_2022.png'"
              alt="Escape the City"
            />
            <div class="mt-6 text-center">
              A top 100 purposeful organisation to escape to in 2022
            </div>
          </div>
        </div>

        <!-- Retreat photo -->
        <div class="mt-24 sm:px-24">
          <img
            class="rounded"
            v-lazy="'/images/beam-team-retreat-2023.jpeg'"
            alt="The Beam team at our 2023 company retreat"
          />
          <div class="mt-6 text-center">
            The Beam team at our 2023 company retreat
          </div>
        </div>
      </div>

      <!-- Benefits -->
      <h2 class="beam-h2 mt-16 sm:mt-20 lg:mt-32">
        Why you’ll love working at Beam
      </h2>

      <div
        class="flex flex-col flex-wrap items-center justify-between text-center sm:flex-row md:px-16"
      >
        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="🚀"
          title="Life-changing work"
          subtitle="Do work that has a huge positive impact on people"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="💷"
          title="Competitive salary"
          subtitle="We offer leading market-leading salaries in social impact"
        >
        </emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="📈"
          title="Share options"
          subtitle="Be a Beam owner with EMI-qualifying share options"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="🏖️"
          title="Annual retreat"
          subtitle="Bond with teammates on a yearly team retreat"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="💻"
          title="New laptop"
          subtitle="Choose between a MacBook or PC - the choice is yours"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="💆🏽‍♀️"
          title="Personal budget"
          subtitle="£200 a year to spend on anything wellbeing"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="💬"
          title="Team talks"
          subtitle="Network with big name tech entrepreneurs"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="🧘‍♂️"
          title="Coaching & wellness"
          subtitle="Unlimited coaching with More Happi and free subscription to Calm"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="🤝"
          title="Financial wellbeing"
          subtitle="1:1 video calls with professional finance experts through Bippit"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="🚴"
          title="Bikes &amp; tech"
          subtitle="Big discounts via Cyclescheme &amp; Techscheme"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="🌴"
          title="Generous holiday"
          subtitle="Up to 30 days per year excluding Bank holidays"
        ></emoji-block>

        <emoji-block
          class="mt-10 sm:w-1/2 lg:mt-16 lg:w-1/4"
          emoji="🏢"
          title="Hybrid working"
          subtitle="Work at our beautiful Hoxton office or at home "
        ></emoji-block>
      </div>

      <!-- Open Positions -->
      <div
        id="positions"
        class="full-screen-mobile mt-16 bg-pale-grey-100 p-8 sm:mt-32 sm:rounded sm:p-12"
      >
        <career-positions></career-positions>
        <div
          class="mx-auto mt-4 text-center font-medium sm:mt-16 sm:w-128 sm:text-base"
        >
          Can't find the right role? We’d still love to hear from you! Send us
          your CV and LinkedIn profile to
          <a href="mailto:careers@beam.org" class="blue-link"
            >careers@beam.org</a
          >.
        </div>
      </div>

      <!-- Diversity -->
      <div class="my-16">
        <h2 class="beam-h2">We’re serious about diversity &amp; inclusion</h2>
        <div>
          <div class="beam-header-intro">
            At Beam, we're committed to hiring diversely and inclusively. We
            especially want to hear from ethnic minority candidates and/or those
            who’ve experienced social disadvantage.
          </div>
          <div class="beam-header-intro">
            63% of people we support come from ethnic minority backgrounds and
            17% have disabilities. We believe we can serve these people best
            when we’re a diverse, inclusive team.
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  props: {
    testimonials: { type: Array },
    galleryImages: { type: Array },
  },
  mounted() {
    this.$nextTick(() => {
      if (window.location.hash === "#positions") {
        this.$scrollTo("#positions", -40)
      }
    })
  },
}
</script>
