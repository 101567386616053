<template>
  <div class="flex flex-row items-end space-x-6 member-form-bill-field">
    <member-form-field
      class="member-form-bill-field-currency"
      :key="currencyField.id"
      :field="currencyField"
      :show-label="showCurrencyLabel"
      v-model="currencyField.value"
    >
      forcerefresh
    </member-form-field>
    <member-form-field
      :key="frequencyField.id"
      :field="frequencyField"
      :show-label="false"
      v-model="frequencyField.value"
      class="inline-member-form-radio-group my-1.5"
    >
      forcerefresh
    </member-form-field>
  </div>
</template>

<script>
export default {
  props: {
    fieldPrefix: { type: String },
    fields: { type: Object },
    showCurrencyLabel: { type: Boolean, default: true },
  },
  computed: {
    currencyField() {
      return this.fields[this.fieldPrefix]
    },
    frequencyField() {
      return this.fields[this.fieldPrefix + "_frequency"]
    },
  },
}
</script>
