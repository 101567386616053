<template>
  <div>
    <div v-if="!finished">
      <div class="text-lg font-medium sm:text-xl">
        {{ questionText }}
      </div>

      <div class="mt-4">
        <div v-if="!questionOptions">
          <textarea
            v-model="questionAnswer"
            class="beam-text-input w-full"
          ></textarea>
        </div>

        <div v-if="questionOptions" class="stack-mt-2">
          <div v-for="option in questionOptions" :key="option">
            <input
              class="mr-2"
              type="radio"
              :id="option"
              :value="option"
              v-model="questionAnswer"
            />
            <label :for="option">{{ option }}</label>
          </div>
        </div>
      </div>

      <div class="mt-4 flex items-center justify-end">
        <div
          @click="saveAnswer"
          class="mr-3 cursor-pointer text-sm text-bluey-grey-100"
        >
          Skip ({{ questionsLeft }} left)
        </div>

        <button
          :disabled="!questionAnswer"
          @click="saveAnswer"
          :class="{ 'button-primary': questionAnswer }"
          class="button px-3 py-2"
        >
          Answer
        </button>
      </div>
    </div>

    <div v-if="finished" class="text-center">
      <span class="font-medium">Thank you!</span>
      Keep being amazing ♥️
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: { type: String },
    userId: { type: Number },
    npsResponseFirst: { type: Boolean, default: false },
  },
  data() {
    return {
      questionId: null,
      questionText: null,
      questionOptions: null,
      questionAnswer: null,
      finished: false,
      questionsLeft: null,
    }
  },
  methods: {
    saveAnswer() {
      const data = {
        survey_answer: {
          survey_question_id: this.questionId,
          answer: this.questionAnswer,
          source: this.source,
          user_id: this.userId,
        },
      }

      this.axios.post(`/survey_answers`, data).finally((response) => {
        this.questionAnswer = null
        this.getQuestion(false)
      })
    },
    getQuestion(initial) {
      let url = `/survey_questions/first_for_user`

      let params = {}

      if (this.userId) params.user_id = this.userId
      if (this.npsResponseFirst) params.nps_response = this.npsResponseFirst

      url += `?${qs.stringify(params)}`

      this.axios.get(url).then((response) => {
        if (response.data.question) {
          this.questionId = response.data.question.id
          this.questionText = response.data.question.question
          this.questionOptions = response.data.question.options
          this.questionsLeft = response.data.questions_left
        } else {
          this.$emit("no-more-questions")

          if (!initial) {
            this.finished = true
          }
        }
      })
    },
  },
  created() {
    this.getQuestion(true)
  },
}
</script>
