export default {
  methods: {
    showField(id) {
      let form = this.thisForm

      switch (id) {
        case "work_status":
          return this.workMilestone()
        case "work_salary_change":
          return this.fieldContains(form.work_status, "sustained work")
        case "work_contract":
        case "employer_id":
        case "work_role":
        case "work_role_custom":
          return this.startedWorkOrSalaryChange()
        case "work_source":
          return this.startedWork()
        case "work_salary_mode":
          return this.startedWorkOrSalaryChange()
        case "work_annual_salary_pennies":
          return (
            this.fieldContains(form.work_salary_mode, "annual") ||
            this.fieldContains(form.work_salary_mode, "both")
          )
        case "work_hourly_salary_pennies":
          return (
            this.fieldContains(form.work_salary_mode, "hourly") ||
            this.fieldContains(form.work_salary_mode, "both")
          )
        case "work_weekly_hours":
          return this.fieldNotEmpty(form.work_salary_mode)
        case "housing_situation":
          return this.housingMilestone()
        case "housing_status":
          return this.housingMilestone()
        case "housing_beam_enabled":
          return this.housingMilestone()
        case "housing_billable":
          return this.fieldEquals(form.housing_beam_enabled, true)
        case "housing_fast_tracked":
          return this.housingMilestone()
        case "tenancy_id":
          return this.housingMilestone()
        case "success_explanation":
        case "success_explanation_actions":
          return (
            this.workMilestone() ||
            (this.fieldEquals(form.housing_beam_enabled, true) &&
              (this.fieldStartsWith(form.housing_status, "Prevented from") ||
                this.fieldStartsWith(form.housing_status, "Moved on from")))
          )
        default:
          return true
      }
    },
    startedWorkOrSalaryChange() {
      let form = this.thisForm
      return (
        this.startedWork() || this.fieldEquals(form.work_salary_change, true)
      )
    },
    startedWork() {
      let form = this.thisForm
      return this.fieldContains(form.work_status, "started work")
    },
    workMilestone() {
      let form = this.thisForm
      return this.fieldContains(form.milestone_type, "work")
    },
    housingMilestone() {
      let form = this.thisForm
      return this.fieldContains(form.milestone_type, "housing")
    },
  },
}
