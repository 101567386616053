<template>
  <div class="bg-navy-800 rounded-lg p-10 mb-6 text-center text-white">
    <p class="font-medium text-3xl mb-4">
      <slot name="title">
        We'll have to use the
        <a
          href="https://betteroffcalculator.co.uk/"
          target="_blank"
          class="underline"
          >Policy In Practice Calculator</a
        >
        to calculate this budget.
      </slot>
    </p>
    <p class="text-2xl">
      <slot name="body">
        This is because you live with adults who aren't your partner
      </slot>
    </p>
  </div>
</template>
<script>
export default {}
</script>
