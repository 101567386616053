<template>
  <div class="mx-auto max-w-2xl">
    <form ref="form" action="/milestones" accept-charset="UTF-8" method="post">
      <div class="stack-mt-12">
        <member-form-field
          v-for="field in form"
          :key="field.id"
          :field="field"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MilestoneConditions from "./MilestoneConditions"
export default {
  mixins: [MemberFormShared, MilestoneConditions],
  data() {
    return {
      tableName: "milestone",
    }
  },
  methods: {
    validateField(field) {
      // Validation specifically for milestone date on non-housing outcomes
      if (field.id == "milestone_date" && !this.housingMilestone()) {
        const date = new Date(field.value)
        if (date > new Date()) {
          field.error_text = "Please enter a date in the past."
          this.errorFields.push(field)
        }
      }

      MemberFormShared.methods.validateField.call(this, field)
    },
  },
}
</script>
