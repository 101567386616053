<template>
  <div>
    <h2 class="beam-h3 mb-8">Check out our {{ jobsCount }} {{ openRoles }}</h2>

    <div class="stack-mt-5">
      <div
        v-for="(roles, name) in departments"
        :key="name"
        class="rounded bg-white p-5"
      >
        <div class="font-medium sm:text-lg lg:text-xl">
          {{ name }}
        </div>

        <div class="stack-mt-2 mt-5">
          <a
            v-for="role in roles"
            :key="role.id"
            :href="role.url"
            class="blue-link block sm:text-lg"
            target="_blank"
          >
            {{ role.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamTailorMixin from "./mixins/TeamTailorMixin"

export default {
  mixins: [TeamTailorMixin],
  data: function () {
    return {
      departments: {},
      jobsCount: 0,
    }
  },
  computed: {
    openRoles() {
      if (this.jobsCount == 1) {
        return "open role"
      } else {
        return "open roles"
      }
    },
  },
  created() {
    this.axios
      .get(this.teamTailorApiUrl, { headers: this.teamTailorHeaders })
      .then((response) => {
        response.data.data.forEach((job) => {
          this.jobsCount = this.jobsCount += 1

          let departmentName

          if (job.relationships.department.data) {
            let department = response.data.included.filter((dept) => {
              return dept.id == job.relationships.department.data.id
            })[0]

            departmentName = department["attributes"].name
          }

          if (!this.departments[departmentName]) {
            this.departments[departmentName] = []
          }

          this.departments[departmentName].push({
            title: job.attributes.title,
            id: job.id,
            url: job.links["careersite-job-url"],
          })
        })
      })
  },
  watch: {
    jobsCount() {
      const el = document.querySelector(".js-open-positions")

      el.textContent = this.jobsCount
      el.parentElement.classList.remove("hidden")
    },
  },
}
</script>
